/***
 * This pipe created because some words inside this app will be replaced with another, following business wording changed.
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'appChangeWording'
})
export class AppChangeWordingPipe implements PipeTransform {
    constructor() { }

    transform(original: string, caseSensitive: boolean = false) {
        const ori = caseSensitive ? original : `${original}`.toLocaleLowerCase();
        switch (ori) {
            case 'distributor': return 'agent';
            default: return original;
        }
    }
}